<template>

	<div class="user">
		<h4>我的优惠券</h4>

		<div class="tab">
			<div :class="{'active':sign==1}" @click="chose(1)">可使用({{n1}})</div>
			<div :class="{'active':sign==3}" @click="chose(3)">已过期({{n3}})</div>
			<div :class="{'active':sign==2}" @click="chose(2)">已使用({{n2}})</div>
			<div class="bottom"></div>
		</div>

		<div class="coulist">
			<div class="couitem" v-for="(item,i) in list.slice((page-1)*pageSize,page*pageSize)" :key="i">
				<div class="c1">
					<p class="t1">{{item.name}}</p>
					<div class="t2">
						<el-popover
							placement="top-start"   width="300" trigger="hover"  :content="item.extra">
							<span slot="reference" style="cursor:pointer;color: #666;">{{item.extra | wuellipsis(20)}}</span>
						</el-popover>
					</div>
					<div class="t3">
						<p >有效期至<span>{{item.out_time}}</span></p>
					 
							<el-popover
							placement="top-start"   title="规则" width="300" trigger="hover"  :content="item.use_range">
							<span slot="reference" style="cursor:pointer;color: #FF7C00;">规则</span>
						</el-popover>
						 
					</div>
					
				</div>
				<div class="c2" :class="bg[sign]">
					<p><span>￥</span>{{item.total}}</p>
				</div>
			</div>
		</div>
		<br/>
		<el-pagination small layout="prev, pager, next"
				:total="total" @current-change="handleCurrentChange"
				:page-size="pageSize" :hide-on-single-page="total==0">
		</el-pagination>
	</div>

</template>

<script>
import {userCouponList} from "@/api/user"
export default {

  data () {
    return {
      sign: 1,
      bg: ['','yhj1', 'yhj2', 'yhj3'],
	  list:[],
	  showtext:"",
	  dig:false,
	  n1:"",
	  n2:"",
	  n3:"",
	  total:0,
	  page:1,
	  pageSize:6

    }
  },
  created () {
	  userCouponList({status:1}).then(res=>{
			this.n1=res.count
			this.total=res.count
			this.list=res.coupon_list
		})
		userCouponList({status:2}).then(res=>{
			this.n2=res.count
		})
		userCouponList({status:3}).then(res=>{
			this.n3=res.count
		})

  },
  methods: {
	 
	handleCurrentChange(currentPage){
			this.page=currentPage
			// this.getlist(this.sign)
	},
	 
    tourl () {
      this.$router.push({ path: '/user/orderInfo' })
    },
	getlist(status){
		var data={
			
			status
		}
		userCouponList(data).then(res=>{
			this.list=res.coupon_list
			this.total=res.count
		})
	},
	show(text){
		this.showtext=text
		this.dig=true
	},
	chose(n){
		this.sign=n
		this.page=1
		this.total=0
		this.getlist(n)
	}
  }
}
</script>

<style lang="scss" scoped="scoped">

	.user{
		padding: 5px 20px 20px;
		background: #ffffff;
		min-height: 500px;
		h4{
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			font-weight: 500;
			line-height: 45px;
			text-indent: 10px;
		}

		.tab{
			display: flex;
			position: relative;
			div{
				cursor: pointer;
				width: 100px;
				height: 45px;
				font-size: 16px;
				font-weight: 500;
				line-height: 45px;
				text-align: center;
				color: #666666;
				border-bottom: 2px solid #cccccc;
				position: relative;
				z-index: 2;
			}
			.active{
				border-bottom: 2px solid #FF7C00;
				color: #ff7c00;
			}
			.bottom{
				width: 100%;
				height: 2px;
				border-bottom: 2px solid #cccccc;
				position: absolute;
				top: 43px;
				left: 0;
				z-index: 0;
			}

		}

		.coulist{
			display: flex;
			flex-wrap: wrap;
			.couitem{
				width: 460px;
				height: 130px;
				background: #f9f9f9;
				border-radius: 1px;
				padding: 10px;
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.c1{
					.t1{
						font-size: 16px;
						font-weight: 600;
						color: #333333;
						line-height: 32px;
					}
					.t2{
						font-size: 14px;
						font-weight: 400;
						color: #999999;
						line-height: 30px;
					
					}
					.t3{
						display: flex;
						font-size: 14px;
						font-weight: 400;
						color: #999999;
						line-height: 40px;
						span{
							color: #333;
							margin-left: 5px;
						}
					}
				}
				.c2{

					width: 113px;
					height: 113px;
					// opacity: 1;
					// border: 1px dashed #ff7c00;
					box-sizing: border-box;
					display: flex;
					justify-content: center;
					align-items: center;
					p{
						font-weight: 500;
						color: #fff;
						font-size:24px;
						// line-height: 95px;
						text-align: center;
						span{
							font-weight: 400;
							color: #fff;
							font-size:14px;
						}
					}

				}
				.yhj1{
					background:url(../../assets/user/yhj1.png) 0 0 no-repeat;
					background-size: cover;
				}
				.yhj2{
					background:url(../../assets/user/yhj2.png) 0 0 no-repeat;
					background-size: cover;
				}
				.yhj3{
					background:url(../../assets/user/yhj3.png) 0 0 no-repeat;
					background-size: cover;
				}

			}
			.couitem:nth-child(odd){
				margin-right: 30px;
			}

		}
	}

</style>
